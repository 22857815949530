<div class="page-preview-project" *ngIf="project">
  <div class="page-preview-project_header">
    <div class="page-content">
      <div class="left-side">
        <a
          [routerLink]="['/']"
          [queryParams]="{ category: project.category }"
          queryParamsHandling="merge"
          class="category"
          >{{ project.category }}</a
        >
        <div class="title">{{ project.project_name }}</div>
        <div class="info">
          <div class="info_item info_item__dates">
            <div class="info_item_icon">
              <img src="./assets/images/icons/calendar.svg" />
            </div>
            <div class="info_item_content">
              <div class="info_item_content_value">
                {{ project.raise_start_date }}
              </div>
              <div class="info_item_content_label">Start Date</div>
            </div>
            <div class="info_item_content">
              <div class="info_item_content_value">
                {{ project.raise_finish_date }}
              </div>
              <div class="info_item_content_label">Finish Date</div>
            </div>
          </div>
          <div class="info_item info_item__investors">
            <div class="info_item_icon">
              <img src="./assets/images/icons/users.svg" />
            </div>
            <div class="info_item_content">
              <div class="info_item_content_value">{{ project.investors }}</div>
              <div class="info_item_content_label">Lessee</div>
            </div>
          </div>
          <div class="info_item info_item__tokens">
            <div class="info_item_icon">
              <img src="./assets/images/icons/coins.svg" />
            </div>
            <div class="info_item_content">
              <div class="info_item_content_value">
                {{ project.usd_target_raise | number }}
              </div>
              <div class="info_item_content_label">Tokens</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
        <div class="right-side-content">
          <div class="project-state">
            <div class="project-state_status">{{ project.status }}</div>
            <div class="invest-info">
              <app-progress
                [fullAmount]="project.usd_target_raise"
                [reachedAmount]="project.usd_collected"
                [class]="'preview-component'"
              ></app-progress>
            </div>

            <div class="amount-notice">
              <span class="amount-notice_value" *ngIf="project"
                >${{ project.usd_minimal_purchase }}</span
              >&nbsp; Minimum Purchase (Tokens)
            </div>
            <div class="invest-info">
              <div class="invest-info_item">
                <div class="invest-info_item_indicator">
                  <div class="invest-info_item_indicator_arc">
                    <app-arc-svg
                      [percentValue]="project.duc_percent_in_target_raise"
                    ></app-arc-svg>
                  </div>
                  <div class="invest-info_item_indicator_icon">
                    <img src="./assets/images/icons/duc.svg" />
                  </div>
                </div>
                <div class="invest-info_item_text">
                  <div class="invest-info_item_text_label">
                    DUC coin ({{ project.duc_percent_in_target_raise }}%)
                  </div>
                  <div class="invest-info_item_text_value">
                    {{ project.usd_from_duc_target_raise | number }} USD
                  </div>
                </div>
              </div>
              <div class="invest-info_item">
                <div class="invest-info_item_indicator">
                  <div class="invest-info_item_indicator_arc">
                    <app-arc-svg
                      [percentValue]="100 - project.duc_percent_in_target_raise"
                      [reverse]="true"
                    ></app-arc-svg>
                  </div>
                  <div class="invest-info_item_indicator_icon">
                    <img src="./assets/images/icons/$.svg" />
                  </div>
                </div>
                <div class="invest-info_item_text">
                  <div class="invest-info_item_text_label">
                    $ ({{ 100 - project.duc_percent_in_target_raise }}%)
                  </div>
                  <div class="invest-info_item_text_value">
                    ${{ project.usd_from_fiat_target_raise | number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="timer" *ngIf="project.status !== 'Expired'">
              <app-timer
                [endTimestamp]="project.finish"
                [isStarted]="project.status === 'ACTIVE'"
                [class]="'preview-component'"
              ></app-timer>
              <!-- <div class="invest-button" *ngIf="project.status === 'ACTIVE'">
                <a
                  [routerLink]="['invest']"
                  class="btn btn-success uppercase width-100"
                >
                  Invest
                </a>
              </div> -->
              <div class="invest-button">
                <a
                  [routerLink]="['invest']"
                  queryParamsHandling="preserve"
                  class="btn btn-success uppercase width-100"
                  *ngIf="project.status === 'ACTIVE'"
                >
                  Lease
                </a>
                <button
                  class="btn btn-success uppercase width-100"
                  *ngIf="project.status !== 'ACTIVE'"
                >
                  coming soon
                </button>
              </div>
            </div>
          </div>

          <div class="investment-returns">
            <div class="investment-returns_title">Lease repayment</div>
            <div class="investment-returns_table_block">
              <table
                class="investment-returns_table"
                border="0"
                cellspacing="0"
                cellpadding="0"
              >
                <thead>
                  <tr>
                    <th>Term<br />(months)</th>
                    <th>Monthly lease back payment in&nbsp;DUCX</th>
                    <th>
                      Money returned (principal + bonus) after the term in DUCX
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ project.staking_months }}</td>
                    <td>{{ project.ducx_staking_monthly_percent }}%</td>
                    <td>{{ project.ducx_staking_total_percent }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="referral-link-box"
            [ngClass]="{ 'referral-link-box_with-link': referralLink }"
          >
            <span class="referral-link-box__title">Referral link</span>
            <span class="referral-link-box__text">
              Share your referral link and get 5% revenue from each Project
              purchase
            </span>

            <div
              class="
                referral-link-box__generate-link-container
                generate-link-container
              "
              *ngIf="!referralLink"
            >
              <label class="generate-link-container__email-label">
                <input
                  class="generate-link-container__email-input"
                  type="email"
                  placeholder="Enter your E-mail"
                  ngModel=""
                  email
                  #refLinkEmailModel="ngModel"
                />
              </label>
              <button
                class="generate-link-container__generate-button"
                [disabled]="
                  !refLinkEmailModel.value || refLinkEmailModel.invalid
                "
                (click)="generateReferralLink(refLinkEmailModel.value)"
              >
                Generate link
              </button>
            </div>

            <div
              class="referral-link-box__link-container link-container"
              *ngIf="referralLink"
            >
              <div class="link-container__link">{{ referralLink }}</div>
              <button
                class="link-container__copy-button"
                ngxClipboard
                [cbContent]="referralLink"
              >
                <img src="assets/images/icons/copy.svg" alt="copy" />
              </button>
            </div>
          </div>

          <div class="location" *ngIf="projectId === 'wupde'">
            <div class="investment-returns_title location__title">
              Location Map ZUR
            </div>
            <img src="./assets/images/pages/preview/location.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="page-preview-project_content"
    [ngClass]="{ 'page-preview-project_content-wupde': projectId === 'wupde' }"
  >
    <div class="page-content">
      <div class="left-side">
        <h2>{{ project.description_title }}</h2>
        <p>
          {{ project.description }}
        </p>
        <!-- <img *ngFor="let img of project.images" [src]="img" /> -->
        <div class="page-preview-project__slider">
          <div class="page-preview-project__slider-main">
            <img [src]="'./assets/images/projects/' + currentImg" alt="" />
          </div>
          <div class="page-preview-project__slider-thumb">
            <div
              class="page-preview-project__slider-thumb-item"
              *ngFor="let img of project.images"
              [ngClass]="{ active: img === currentImg }"
              (click)="setCurrentImg(img)"
            >
              <img [src]="'./assets/images/projects/' + img" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
