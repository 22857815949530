import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {IndexComponent} from './pages/index/index.component';
import {PreviewProjectComponent} from './pages/preview-project/preview-project.component';
import {InvestProjectComponent} from './pages/invest-project/invest-project.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
  }, {
    path: ':projectId',
    component: PreviewProjectComponent
  },
  {
    path: ':projectId/invest',
    component: InvestProjectComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
