<div class="page-content">
  <ul class="categories-menu">
    <li class="categories-menu_item">
      <a
        class="categories-menu_item_link"
        (click)="checkQuery()"
        [ngClass]="{ active: category === 'lease' }"
        [routerLinkActive]="'active'"
        [routerLink]="['/']"
        [queryParams]="{ category: 'lease' }"
        queryParamsHandling="merge"
        >Lease</a
      >
    </li>
    <li class="categories-menu_item">
      <a
        class="categories-menu_item_link"
        (click)="checkQuery()"
        [routerLinkActive]="'active'"
        [routerLink]="['/']"
        [queryParams]="{ category: 'earn' }"
        queryParamsHandling="merge"
        >Earn</a
      >
    </li>
    <li class="categories-menu_item">
      <a
        class="categories-menu_item_link"
        (click)="checkQuery()"
        [routerLinkActive]="'active'"
        [routerLink]="['/']"
        queryParamsHandling="merge"
        [queryParams]="{ category: 'live it up' }"
        >Live it up</a
      >
    </li>
  </ul>
</div>

<div class="projects">
  <div class="page-content">
    <div class="projects-list">
      <div class="projects-list_item" *ngFor="let project of projectsList">
        <app-project-preview [projectParams]="project"></app-project-preview>
      </div>
    </div>
  </div>
</div>
