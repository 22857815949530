import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit {
  @Input('fullAmount') public fullAmount;
  @Input('reachedAmount') public reachedAmount;
  @Input('class') public addClass;

  public filledPercent;

  constructor() {}

  ngOnInit(): void {
    this.filledPercent = Math.floor(
      this.reachedAmount / (this.fullAmount / 100)
    );
  }
}
