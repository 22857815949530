<div class="page-invest-project">
  <div class="modal" *ngIf="isModal" (click)="closeModal()">
    <div class="modal__wrap">
      <img
        src="../../../assets/images/icons/ok.svg"
        alt=""
        class="modal__img"
      />
      <div class="modal__title">Thanks for supporting this project!</div>
      <div class="modal__text">
        Interested in more passive income opportunities? Lease another project!
      </div>
      <!-- <button class="modal__btn btn btn-success uppercase continue-button">
        ok
      </button> -->
      <a
        [routerLink]="['/']"
        queryParamsHandling="preserve"
        class="modal__btn btn btn-success uppercase continue-button"
        >Back to the projects</a
      >
    </div>
  </div>
  <div class="page-content">
    <div class="title">Lease</div>
    <div class="right-side revert">
      <div class="right-side_content" *ngIf="currentProject">
        <div class="right-side-block">
          <div class="right-side-block_title">FIAT - DUC Ratio</div>
          <div class="fiat-rate">
            <div class="fiat-rate_icons">
              <div class="fiat-rate_icons_item">
                <img src="./assets/images/icons/coins/duc-neg.svg" />
              </div>
              <div class="fiat-rate_icons_item">
                <img src="./assets/images/icons/coins/just-usd.svg" />
              </div>
            </div>
            <div class="fiat-rate_line">
              <div
                class="fiat-rate_line_item fiat-rate_line_duc"
                style="width: 28%"
              ></div>
              <div
                class="fiat-rate_line_item fiat-rate_line_usd"
                style="width: 71%"
              ></div>
            </div>
            <div class="fiat-rate_values">
              <div class="fiat-rate_values_item">
                <div class="fiat-rate_values_item_coin">
                  DUC coin ({{ currentProject.duc_percent_in_target_raise }}%)
                </div>
                <div class="fiat-rate_values_item_value">
                  {{ currentProject.usd_from_duc_target_raise | number }} USD
                </div>
              </div>
              <div class="fiat-rate_values_item">
                <div class="fiat-rate_values_item_coin">
                  $ ({{ currentProject.fiat_percent_in_target_raise }}%)
                </div>
                <div class="fiat-rate_values_item_value">
                  ${{ currentProject.usd_from_fiat_target_raise | number }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-side-block">
          <div class="right-side-block_title">
            First payment to be released to your account
          </div>
          <div class="right-side-block_title blue">
            In {{ currentProject.months_between_raise_and_staking }} months
          </div>
        </div>
      </div>
    </div>
    <div class="left-side revert">
      <form
        class="invest-form"
        [formGroup]="investGroup"
        (ngSubmit)="getAddresses()"
      >
        <div class="invest-form_group">
          <div class="invest-form_coins">
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('duc')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="duc"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/duc.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/duc-neg.svg"
                />
                <span class="checkbox-button_text">DUC</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('btc')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="btc"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/btc.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/btc-neg.svg"
                />
                <span class="checkbox-button_text">BTC</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('eth')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="eth"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/eth.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/eth-neg.svg"
                />
                <span class="checkbox-button_text">ETH</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('usdc')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="usdc"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/usdc.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/usdc-neg.svg"
                />
                <span class="checkbox-button_text">USDC</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('usdt')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="usdt"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/usdt.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/usdt-neg.svg"
                />
                <span class="checkbox-button_text">USDT</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
            <div class="invest-form_coins_item">
              <label class="checkbox-button" (click)="changeCurrency('usd')">
                <input
                  class="checkbox-button_input"
                  type="radio"
                  name="currency"
                  formControlName="currency"
                  value="usd"
                />
                <img
                  class="checkbox-button_icon"
                  src="./assets/images/icons/coins/usd.svg"
                />
                <img
                  class="checkbox-button_icon is-checked"
                  src="./assets/images/icons/coins/usd-neg.svg"
                />
                <span class="checkbox-button_text">USD</span>
                <span class="checkbox-button_mask"></span>
              </label>
            </div>
          </div>
        </div>

        <div
          class="invest-form_group"
          *ngIf="investGroup.value.currency !== 'usd'"
        >
          <input
            class="amount-input"
            type="text"
            placeholder="Enter amount (USD)"
            formControlName="amount"
            (ngModelChange)="checkAmount()"
            [ngClass]="{ error: amountError }"
          />
          <div class="amount-notice" *ngIf="!amountError">
            <span class="amount-notice_value" *ngIf="currentProject"
              >${{ currentProject.usd_minimal_purchase }}</span
            >&nbsp; Minimum Purchase (Tokens)
          </div>
          <div class="amount-notice" *ngIf="amountError">
            <div class="amount-notice_error">
              {{ investGroup.value.currency.toUpperCase() }} {{ amountError }}
            </div>
          </div>
        </div>

        <div
          class="invest-form_group"
          *ngIf="investGroup.value.currency !== 'usd'"
        >
          <span class="invest-form_group_label">Enter your Email</span>
          <input
            class="email-input"
            type="email"
            placeholder="example@example.ex"
            formControlName="email"
          />
        </div>

        <div class="invest-form_group" *ngIf="address">
          <span class="invest-form_group_label">
            Send your
            <span class="invest-form_group_label_value">{{ totalAmount }}</span>
            {{ investGroup.value.currency.toUpperCase() }} to the following
            address
          </span>
          <div class="address-block">
            <div class="address-block_text">
              {{ address }}
            </div>
            <div
              class="address-block_button"
              copied-text="Copied"
              [ngClass]="{ copied: copiedAddress }"
            >
              <button
                class="copy-btn"
                type="button"
                ngxClipboard
                [cbContent]="address"
                (cbOnSuccess)="onCopied()"
              >
                Copy
              </button>
            </div>
          </div>
          <div class="qr-code-block">
            <div class="invest-form_group_label qr-code-block_label">
              or scan this QR code
            </div>
            <div class="qr-code-block_img">
              <qrcode
                [qrdata]="address"
                [width]="256"
                [errorCorrectionLevel]="'M'"
              ></qrcode>
            </div>
          </div>
        </div>

        <div
          class="invest-form_group transparent"
          *ngIf="investGroup.value.currency !== 'usd'"
        >
          <button
            class="btn btn-success uppercase continue-button"
            [disabled]="
              !investGroup.value.amount ||
              amountError ||
              !investGroup.controls['email'].valid
            "
          >
            Continue&nbsp;&nbsp;
            <img src="./assets/images/icons/right-arrow-white.svg" />
          </button>
        </div>
      </form>
      <div
        class="page-invest-project__err"
        *ngIf="investGroup.value.currency === 'usd'"
      >
        Currently unavailable
      </div>
    </div>
  </div>
</div>
