<div class="timer-block" [ngClass]="addClass">
  <div class="timer-block_item">
    <div class="timer-block_item_number">{{leftTime.day}}</div>
    <div class="timer-block_item_label">Day</div>
  </div>
  <div class="timer-block_item">
    <div class="timer-block_item_number">{{leftTime.hour}}</div>
    <div class="timer-block_item_label">Hour</div>
  </div>
  <div class="timer-block_item">
    <div class="timer-block_item_number">{{leftTime.minute}}</div>
    <div class="timer-block_item_label">Minute</div>
  </div>
  <div class="timer-block_item">
    <div class="timer-block_item_number">{{leftTime.second}}</div>
    <div class="timer-block_item_label">Second</div>
  </div>
</div>
