import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-arc-svg',
  templateUrl: './arc-svg.component.html',
  styleUrls: ['./arc-svg.component.scss']
})
export class ArcSvgComponent implements OnInit {
  @ViewChild('arcView') private arcView;
  @Input('percentValue') private percentValue;
  @Input('reverse') private reverse;
  constructor() {

  }

  private indicator(x, y, radius, angleStart, angleEnd): string {
    const start = this.polarToCartesian(x, y, radius, -angleEnd);
    const end = this.polarToCartesian(x, y, radius, angleStart);

    const largeArc = angleEnd - angleStart <= 180 ? 0 : 1;
    return [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArc, 1, end.x, end.y
    ].join(' ');
  }

  private polarToCartesian(cx, cy, radius, deg): {x: number; y: number} {
    const rad = (deg - (!this.reverse ? 90 : 180)) * Math.PI / 180;
    return {
      x: cx + (radius * Math.cos(rad)),
      y: cy + (radius * Math.sin(rad))
    };
  }

  private iniArc(): void {
    const scene = this.arcView.nativeElement;
    const path2 = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path2.setAttribute('fill-opacity', '1');
    path2.setAttribute('stroke', '#DCE4F6');
    path2.setAttribute('fill', '#ffffff');
    path2.setAttribute('stroke-width', '5');
    path2.setAttribute('stroke-linecap', 'round');
    path2.setAttribute('stroke-linejoin', 'round');
    path2.setAttribute('d', this.indicator(21, 21, 18.45, 0, 359.99999));
    scene.appendChild(path2);


    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute('fill-opacity', '0');
    path.setAttribute('stroke', '#1034A6');
    path.setAttribute('stroke-width', '5');
    path.setAttribute('stroke-linecap', 'round');
    path.setAttribute('stroke-linejoin', 'round');
    if (!this.reverse) {
      path.setAttribute('d', this.indicator(21, 21, 18.45, 0, 360 * (this.percentValue / 100) - 0.0001));
    } else {
      path.setAttribute('d', this.indicator(21, 21, 18.45, 0, 360 * (this.percentValue / 100) - 0.0001));
    }
    scene.appendChild(path);

  }

  ngOnInit(): void {
    setTimeout(() => {
      this.iniArc();
    });
  }

}
