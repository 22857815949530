<a [routerLink]="projectParams.string_id" queryParamsHandling="preserve" class="project-preview">
  <div
    class="project-preview_image"
    [ngStyle]="{
      backgroundImage:
        'url(/assets/images/projects/' + projectParams.default_image + ')'
    }"
  ></div>
  <div class="project-preview_content">
    <div class="project-preview_description">
      <div class="project-preview_description_title">
        {{ projectParams.project_name }}
      </div>
      <div class="project-preview_description_text">
        {{ projectParams.description }}
      </div>
    </div>
  </div>

  <div class="project-preview_additional">
    <div class="project-preview_additional_timer">
      <app-timer
        [endTimestamp]="projectParams.raise_finish_date"
        [isStarted]="projectParams.isStarted"
      ></app-timer>
    </div>
    <div class="project-preview_additional_progress">
      <app-progress
        [fullAmount]="projectParams.usd_target_raise"
        [reachedAmount]="projectParams.usd_collected"
      ></app-progress>
    </div>
  </div>
</a>
