import {
  AfterContentInit,
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import moment from 'moment';

const oneDayMS = 86400000;
@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
})
export class TimerComponent implements OnDestroy, AfterContentInit {
  @Input('endTimestamp') public endTimestamp;
  @Input('isStarted') public isStarted;
  @Input('class') public addClass;

  public leftTime = {
    day: 0,
    hour: 0,
    minute: 0,
    second: 0,
  };

  private timer;

  constructor() {}

  ngOnDestroy(): void {
    clearTimeout(this.timer);
  }

  ngAfterContentInit(): void {
    this.startTimer();
  }

  private startTimer(): void {
    if (this.isStarted) {
      const allLeftSeconds = Math.abs(
        (moment(+this.endTimestamp * 1000) as any) - new Date().getTime()
      );
      let day = Math.floor(allLeftSeconds / oneDayMS) as any;
      let hour = Math.floor((allLeftSeconds % oneDayMS) / 3600000) as any;
      let minute = Math.floor((allLeftSeconds % 3600000) / 60000) as any;
      let second = Math.floor((allLeftSeconds % 60000) / 1000) as any;

      day = (day < 10 ? '0' : '') + day;
      hour = (hour < 10 ? '0' : '') + hour;
      minute = (minute < 10 ? '0' : '') + minute;
      second = (second < 10 ? '0' : '') + second;

      this.leftTime = {
        day,
        hour,
        minute,
        second,
      };
    } else {
      this.leftTime = {
        day: 0,
        hour: 0,
        minute: 0,
        second: 0,
      };
    }
    this.timer = setTimeout(() => {
      this.startTimer();
    }, 300);
  }
}
