import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivationEnd, Router } from '@angular/router';
import BigNumber from 'bignumber.js';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-invest-project',
  templateUrl: './invest-project.component.html',
  styleUrls: ['./invest-project.component.scss'],
})
export class InvestProjectComponent implements OnInit, OnDestroy {
  private routeSubscriber;
  public investGroup: FormGroup;
  public copiedAddress: boolean;
  public address;
  private projectName;
  public currentProject;
  public amountError;
  private rates;
  public isModal = false;
  private prevCurrency;
  private prevAmount;
  private addresses;
  public totalAmount;
  private decimals = {
    DUC: 8,
    ETH: 18,
    BTC: 8,
  };
  private referralCode: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: Router,
    private _http: HttpClient,
    private cookieService: CookieService
  ) {
    this.routeSubscriber = this.route.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.referralCode = event.snapshot.queryParams.referral;
        if (this.referralCode) {
          this.cookieService.put('referral', this.referralCode);
        }

        this.projectName = event.snapshot.params.projectId;
        this.getProjectData();
      }
    });

    this._http
      .get(`https://tokenization.centuriongm.com/api/v1/usd_rates/`)
      .subscribe((res: any) => {
        this.rates = res;
      });
    this.investGroup = this.formBuilder.group({
      currency: ['duc', Validators.compose([Validators.required])],
      amount: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  private getProjectData(): void {
    this._http
      .get(
        `https://tokenization.centuriongm.com/api/v1/projects/${this.projectName}/`
      )
      .subscribe((res: any) => {
        this.currentProject = res;
        if (res.status !== 'ACTIVE') {
          this.route.navigateByUrl('/');
        }
        console.log(res);
      });
  }

  public closeModal(): void {
    this.isModal = false;
  }

  public changeCurrency(currency): void {
    debugger;
    if (this.prevCurrency !== currency && currency !== 'usd') {
      if (this.address) {
        if (currency === 'duc') {
          this.address = this.addresses.duc_address;
        }
        if (currency === 'btc') {
          this.address = this.addresses.btc_address;
        }
        if (currency === 'eth' || currency === 'usdc' || currency === 'usdt') {
          this.address = this.addresses.eth_address;
        }
      }
      this.totalAmount = new BigNumber(
        new BigNumber(this.rates[currency.toUpperCase()])
          .multipliedBy(this.investGroup.value.amount)
          .toFixed(this.decimals[currency.toUpperCase()])
      ).toString(10);
      this.checkAmount(currency);
    }

    if (currency === 'usd') {
      this.address = '';
    }
    this.prevCurrency = currency;
  }

  public getAddresses(): void {
    const { currency, amount } = this.investGroup.value;
    if (
      this.prevCurrency === currency &&
      this.prevAmount === amount &&
      currency !== 'usd'
    ) {
      this.isModal = true;
      return;
    }
    this.address = '';
    this.prevAmount = amount;
    this.prevCurrency = currency;
    if (currency !== 'usd') {
      this.totalAmount = new BigNumber(
        new BigNumber(this.rates[this.investGroup.value.currency.toUpperCase()])
          .multipliedBy(this.investGroup.value.amount)
          .toFixed(this.decimals[this.investGroup.value.currency.toUpperCase()])
      ).toString(10);
      this._http
        .post(
          `https://tokenization.centuriongm.com/api/v1/projects/${this.currentProject.string_id}/invest`,
          {
            email: this.investGroup.value.email,
            id: this.currentProject.string_id,
          },
          {
            withCredentials: true,
          }
        )
        .subscribe((res: any) => {
          console.log(res, 'invest');
          this.addresses = res;

          if (currency === 'duc') {
            this.address = res.duc_address;
          }
          if (currency === 'btc') {
            this.address = res.btc_address;
          }
          if (
            currency === 'eth' ||
            currency === 'usdc' ||
            currency === 'usdt'
          ) {
            this.address = res.eth_address;
          }
        });
    } else {
      this.getRedirectUrl();
    }
  }

  private getRedirectUrl(): void {
    this._http
      .post(
        `https://tokenization.centuriongm.com/api/v1/projects/${this.currentProject.string_id}/create_charge`,
        {
          usd_amount: +this.investGroup.value.amount,
          email: this.investGroup.value.email,
        }
      )
      .subscribe((res: any) => {
        window.location.href = res.redirect_url;
      });
  }

  public checkAmount(currency?: string): any {
    this.amountError = '';
    if (
      +this.investGroup.value.amount >= this.currentProject.usd_minimal_purchase
    ) {
      const curr = currency
        ? currency.toUpperCase()
        : this.investGroup.value.currency.toUpperCase();
      this._http
        .post(
          `https://tokenization.centuriongm.com/api/v1/projects/${this.currentProject.string_id}/validate_usd_amount`,
          {
            usd_amount: +this.investGroup.value.amount,
            currency: curr,
          }
        )
        .subscribe((res: any) => {
          this.amountError =
            res.status === 'MUCH'
              ? 'limit reached! Payment only available in other currencies.'
              : '';
        });
    } else {
      this.amountError = `payment limit cannot be less than ${this.currentProject.usd_minimal_purchase}`;
    }
  }

  ngOnInit(): void {}

  public onCopied(): void {
    this.copiedAddress = true;
    setTimeout(() => {
      this.copiedAddress = false;
    }, 3000);
  }

  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }
}
