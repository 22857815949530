<footer class="footer">
  <div class="page-content footer__content">
    <a href="https://centuriongm.com" target="_blank"
      ><img src="./assets/images/footer/logo.svg" alt=""
    /></a>
    <div class="footer__box">
      <div class="footer__title">Contact Us</div>
      <div class="footer__text">
        <p>Centurion Global Limited</p>
        <p>QUALITY CORPORATE SERVICES LTD.</p>
        <p>SUITE 102, CANNON PLACE, P.O. BOX 712</p>
        <p>NORTH SOUND ROAD, GEORGE TOWN, GRAND CAYMAN,</p>
        <p>KY1-9006, CAYMAN ISLANDS</p>
      </div>
    </div>
    <div class="footer__box">
      <div class="footer__title">Legal</div>
      <div class="footer__text footer__links">
        <a href="#">Cookie Policy</a>
        <a href="#">Privacy Policy</a>
        <a href="#">Impressum</a>
      </div>
    </div>
  </div>
  <div class="page-content">
    <div class="footer__privacy">© 2020 Centurion</div>
  </div>
</footer>
