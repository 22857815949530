import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivationEnd, Router } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-preview-project',
  templateUrl: './preview-project.component.html',
  styleUrls: ['./preview-project.component.scss'],
})
export class PreviewProjectComponent implements OnInit, OnDestroy {
  private routeSubscriber;
  public projectId;
  public project;
  public currentImg;

  public referralLink = '';

  constructor(private route: Router, private _http: HttpClient) {
    this.routeSubscriber = this.route.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.projectId = event.snapshot.params.projectId;

        this.getProjectData();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }

  public setCurrentImg(img): void {
    this.currentImg = img;
  }

  private getProjectData(): void {
    this._http
      .get(
        `https://tokenization.centuriongm.com/api/v1/projects/${this.projectId}/`
      )
      .subscribe((res: any) => {
        this.project = res;

        this.currentImg = res.default_image;

        this.project.images.unshift(res.default_image);

        const isAfter = moment
          .utc(res.raise_finish_date * 1000)
          .isAfter(moment.utc());
        if (
          !isAfter &&
          +this.project.usd_collected < +this.project.usd_target_raise &&
          res.raise_finish_date
        ) {
          this.project.status = 'Expired';
        }
        this.project.finish = res.raise_finish_date;
        this.project.raise_start_date = this.project.raise_start_date
          ? moment(res.raise_start_date * 1000).format('DD.MM.YYYY')
          : 'TBD';
        this.project.raise_finish_date = this.project.raise_finish_date
          ? moment(res.raise_finish_date * 1000).format('DD.MM.YYYY')
          : 'TBD';

        console.log(this.project, 'this.pro');
      });
  }

  public generateReferralLink(email: string): void {
    this._http
      .post('https://tokenization.centuriongm.com/api/v1/referral_code', {
        email,
      })
      .subscribe((response: { own_ref_code: string }) => {
        this.referralLink = `https://tokenization.centuriongm.com/${this.projectId}?referral=${response.own_ref_code}`;
      });
  }
}
