import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectPreviewComponent } from './components/project-preview/project-preview.component';
import { TimerComponent } from './components/timer/timer.component';
import { ProgressComponent } from './components/progress/progress.component';
import { PreviewProjectComponent } from './pages/preview-project/preview-project.component';
import { ArcSvgComponent } from './components/arc-svg/arc-svg.component';
import { InvestProjectComponent } from './pages/invest-project/invest-project.component';
import { ClipboardModule } from 'ngx-clipboard';
import { HttpClientModule } from '@angular/common/http';
import { QRCodeModule } from 'angularx-qrcode';
import { CookieModule } from 'ngx-cookie';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    HeaderComponent,
    FooterComponent,
    ProjectPreviewComponent,
    TimerComponent,
    ProgressComponent,
    PreviewProjectComponent,
    ArcSvgComponent,
    InvestProjectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    HttpClientModule,
    QRCodeModule,
    CookieModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
