<div class="progress-block" [ngClass]="addClass">
  <div class="progress-block_title">
    <span>Reached</span>
    <span>&nbsp;</span>
    <span>${{ reachedAmount | number }}</span>
  </div>
  <div class="progress-block_line">
    <div
      class="progress-block_line_progress"
      [ngStyle]="{ width: filledPercent + '%' }"
    >
      <span>{{ filledPercent }}%</span>
    </div>
  </div>
  <div class="progress-block_limits">
    <div class="progress-block_limits_item">$0</div>
    <div class="progress-block_limits_item">${{ fullAmount | number }}</div>
  </div>
</div>
