import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import moment from 'moment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, OnDestroy {
  public projectsList = [];
  private projects = [];
  private routeSubscriber;
  public category;

  constructor(private route: Router, private _http: HttpClient) {
    this.routeSubscriber = this.route.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.category = event.snapshot.queryParams.category || 'lease';
        this.checkAndApplyCategory();
      }
    });
  }

  ngOnInit(): void {
    this._http
      .get('https://tokenization.centuriongm.com/api/v1/projects/')
      .subscribe((res: any) => {
        this.projects = res;
        this.checkAndApplyCategory();
      });
  }

  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }

  public checkQuery(): void {
    this.routeSubscriber = this.route.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.category = event.snapshot.queryParams.category || 'lease';
        this.checkAndApplyCategory();
      }
    });
  }

  private checkAndApplyCategory(): void {
    if (this.projects) {
      this.projectsList =
        this.projects[this.category] && this.projects[this.category];
      this.projectsList = this.projectsList.map((project) => {
        const isAfter = moment
          .utc(project.raise_finish_date * 1000)
          .isAfter(moment.utc());
        if (
          (!isAfter && +project.usd_collected < +project.usd_target_raise) ||
          project.status !== 'ACTIVE'
        ) {
          project.isStarted = false;
        } else {
          project.isStarted = true;
        }
        return project;
      });
    }
    // console.log(this.projectsList,zz)
  }
}
